<template>

  <div class="outer-wrapper">
    <div
      class="border rounded text-white item-holder"
      :class="itemClasses"
    >
      <b-form-checkbox
        v-model="isSelected"
        :disabled="statusVariant === 'success' || rgReport.invoice__created_from_challan"
      />
      <span
        class="group-number"
        @click="displayRGReportDetails"
      >#{{ rgReport.rack_group_no }}</span>

      <b-form @submit.prevent="updateBucketNumber">
        <b-form-input
          v-model="bucketNumber"
          type="text"
          :disabled="statusVariant === 'success' || submitting || rgReport.invoice__created_from_challan"
        />
      </b-form>
    </div>
    <span
      v-if="rgReport.attention_required===true"
      class="attention-required"
    />
    <span
      v-else-if="rgReport.attention_required===false"
      class="attention-completed"
    />
  </div>

</template>

<script>
import axios from 'axios'
import { BFormInput, BFormCheckbox, BForm } from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormInput,
    BFormCheckbox,
  },
  props: {
    rgReport: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      submitting: false,
    }
  },
  computed: {
    status: {
      get() {
        return this.rgReport.status
      },
      set(newValue) {
        this.rgReport.status = newValue
      },
    },
    statusVariant() {
      switch (this.status) {
        case 'Pickup Completed':
          return 'success'

        case 'Pickup Initiated':
          return 'warning'

        default:
          return 'secondary'
      }
    },
    itemClasses() {
      let classes = `bg-${this.statusVariant} border-${this.statusVariant}`
      if (this.rgReport.is_downloaded) {
        classes += ' downloaded'
      }
      return classes
    },
    bucketNumber: {
      get() {
        return this.rgReport.pickup_bucket_number
      },
      set(newValue) {
        this.rgReport.pickup_bucket_number = newValue
      },
    },
    isSelected: {
      get() {
        return this.$store.getters['operations/selectedRackGroups'].includes(this.rgReport.id)
      },
      set(newVal) {
        if (newVal) {
          this.$store.dispatch('operations/selectRackGroups', { ids: [this.rgReport.id] })
        } else {
          this.$store.dispatch('operations/unSelectRackGroups', { ids: [this.rgReport.id] })
        }
      },
    },
  },
  methods: {
    async updateBucketNumber() {
      this.submitting = true
      const payload = { id: this.rgReport.id, pickup_bucket_number: this.bucketNumber }
      const { data } = await axios.post('operations/update_rg_bucket_no', payload)
      this.status = data.status
      this.submitting = false
    },
    displayRGReportDetails() {
      this.$store.dispatch('operations/displayRGReportDetails', { id: this.rgReport.id })
    },
  },
}

</script>

<style scoped>

.outer-wrapper{
  display: flex;
  align-items: center;
  width:120px;
}

.item-holder{
  display: flex;
  width:100px;
  align-items: center;
  padding: 1px 5px;
}

.downloaded{
  border-right: 5px solid green !important;
}

.item-holder input{
  height: 1.7rem;
  padding: 2px;
}

.group-number {
  margin-right: 5px;
}

</style>
