<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="3"
      >
        <missing-bills :need-refresh="refreshTopVisuals" />
      </b-col>
      <b-col
        cols="12"
        md="3"
      >
        <attention-required-bills :need-refresh="refreshTopVisuals" />
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <h4>RackGroup Reports</h4>
      </b-card-header>
      <b-card-body>
        <Filters
          v-model="filters"
          :rack-group-options="rackGroupOptions"
        />
        <div
          v-if="!(errorMessage)"
          class="text-right mb-2"
        >
          <b-button
            variant="outline-primary"
            class="mr-1 refresh-btn"
            :class="isNewItemAvailable ? 'pr-3' : ''"
            :disabled="loading"
            @click="handleRefresh"
          >
            Refresh
            <span
              v-if="isNewItemAvailable"
              class="badge badge-up badge-pill badge-success badge-minimal badge-glow"
            />
          </b-button>
          <b-button
            variant="outline-primary"
            class="mr-1"
            :disabled="!selectedRackGroups.length || loading || isDownloading"
            @click="downloadRGReport"
          >
            Download
            <b-spinner
              v-if="isDownloading"
              label="Small Spinner"
              variant="primary"
              small
            />
          </b-button>
          <b-button
            variant="outline-primary"
            :disabled="!selectedRackGroups.length || loading || isCompleting"
            @click="completeRGReport"
          >
            Mark Completed
            <b-spinner
              v-if="isCompleting"
              label="Small Spinner"
              variant="primary"
              small
            />
          </b-button>
        </div>
        <b-alert
          variant="danger"
          :show="errorMessage ? true : false"
        >
          <p
            class="alert-body"
          >
            {{ errorMessage }}
          </p>
        </b-alert>
        <div
          v-if="loading"
          class="text-center"
        >
          <b-spinner
            label="Small Spinner"
            variant="primary"
          />
        </div>
        <div v-if="!loading && !errorMessage">
          <b-table
            responsive
            striped
            hover
            :items="tableItems"
            :fields="tableFields"
            class="compact-table"
            no-local-sorting
            show-empty
            empty-text="No Records to display!"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @sort-changed="sortingChanged"
          >
            <template
              v-for="group in rackGroupOptions"
              v-slot:[`head(${group})`]
            >
              <div
                :key="group"
                style="display:flex"
              >
                <b-form-checkbox
                  :disabled="selectableGroupWiseRgReports[group].length === 0"
                  @change="groupSelectAllHandler($event, group)"
                />
                <div>{{ `#${group}` }}</div>
              </div>
            </template>

            <template #cell(invoice__party__identifier)="{item}">
              {{ item.invoice__party__identifier && item.invoice__party__identifier.split("|")[0] }}
            </template>

            <template #cell(invoice__bill_no)="{item}">
              <span class="d-flex align-items-center">
                <span
                  v-if="item.difference_html_available"
                >
                  <a
                    href="#"
                    style="text-decoration: underline"
                    @click.prevent="viewDiffrenceHandler(item.id, 'difference')"
                  >{{ item.invoice__bill_no }}</a>
                </span>
                <span v-else>
                  <a
                    href="#"
                    style="text-decoration: underline"
                    @click.prevent="viewDiffrenceHandler(item.id, 'items')"
                  >{{ item.invoice__bill_no }}</a>
                </span>
                <span
                  v-if="item.invoice__is_modified"
                  class="is-modified-bill"
                />
                <span
                  v-else
                  class="modified-placeholder"
                />
                <span
                  v-if="item.invoice__is_urgent"
                  class="is-urgent-bill"
                />
              </span>
              <span v-if="item.invoice__created_from_challan">
                (from challan)
              </span>
            </template>

            <template
              v-for="group in rackGroupOptions"
              v-slot:[`cell(${group})`]="{item}"
            >
              <rack-group-items
                :key="group"
                :rg-reports="item.rg_reports.filter(report => report.rack_group_no === group)"
              />
            </template>

            <template #cell(invoice__bill_delivery_info__generated_at)="{item}">
              {{ item.invoice__bill_delivery_info__generated_at && formatedDate(item.invoice__bill_delivery_info__generated_at, 'DD-MM-YYYY LT') || '-' }}
            </template>

          </b-table>
          <detailed-pagination
            :total-items="totalItems"
            :per-page="perPage"
            :current-page="currentPage"
            @page-changed="handlePageChange"
          />
        </div>
      </b-card-body>
    </b-card>

    <bill-difference-modal
      v-if="displayBillDifferenceId"
      :id="displayBillDifferenceId"
      :mode="displayBillDifferenceMode"
      @modal-closed="handleCloseDifferenceModal"
    />

    <r-g-report-details-modal
      v-if="displayRGDetailsId"
      :id="displayRGDetailsId"
      @modal-closed="closeRGDetailsModal"
    />
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BTable, BAlert, BSpinner, VBTooltip,
  BFormCheckbox, BButton, BRow, BCol,
} from 'bootstrap-vue'
import axios from 'axios'
import downloadFileMixin from '@/mixins/downloadFileMixin'
import getParsedQueryString from '@/utils/headerQueryParser'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DetailedPagination from '@/components/UI/DetailedPagination.vue'
import dateMixin from '@/mixins/dateMixin'
import Filters from '@/components/Operations/RackGroupReports/Filters.vue'
import RackGroupItems from '@/components/Operations/RackGroupReports/RackGroupItems.vue'
import BillDifferenceModal from '@/components/Operations/RackGroupReports/BillDifferenceModal.vue'
import RGReportDetailsModal from '@/components/Operations/RackGroupReports/RGReportDetailsModal.vue'
import MissingBills from '@/components/Delivery/DeliveryStatus/MissingBills.vue'
import AttentionRequiredBills from '@/components/Operations/RackGroupReports/AttentionRequiredBills.vue'

import WS, { WS_ROOMS, WS_EVENTS } from '@/utils/ws'
import bus from '@/bus'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BTable,
    BAlert,
    BSpinner,
    BRow,
    BCol,
    BFormCheckbox,
    BButton,
    DetailedPagination,
    Filters,
    RackGroupItems,
    BillDifferenceModal,
    MissingBills,
    RGReportDetailsModal,
    AttentionRequiredBills,
  },
  mixins: [dateMixin, downloadFileMixin],
  data() {
    return {
      tableItems: [],
      filters: {},
      sortBy: 'invoice__bill_no',
      sortDesc: true,
      perPage: 50,
      totalItems: 0,
      currentPage: 1,
      loading: false,
      errorMessage: null,
      isDownloading: false,
      isCompleting: false,
      rackGroupOptions: [],
      refreshTopVisuals: false,
      displayBillDifferenceId: null,
      displayBillDifferenceMode: null,
      isNewItemAvailable: false,
    }
  },
  computed: {
    tableFields() {
      const rackGroupColumns = this.rackGroupOptions.map(item => (
        { key: item, label: `#${item}`, thStyle: { width: '6%' } }
      ))

      return [
        { key: 'invoice__party__area', label: 'Area', sortable: true },
        { key: 'invoice__party__identifier', label: 'Party', sortable: true },
        { key: 'invoice__bill_no', label: 'Bill No.', sortable: true },
        ...rackGroupColumns,
        { key: 'invoice__bill_delivery_info__generated_by', label: 'Bill Created By', sortable: true },
        { key: 'invoice__bill_delivery_info__generated_at', label: 'Bill Created at' },
      ]
    },
    selectableGroupWiseRgReports() {
      const groupWiseReports = {}
      this.rackGroupOptions.forEach(group => {
        groupWiseReports[group] = []
      })

      this.tableItems.forEach(item => {
        item.rg_reports.forEach(report => {
          if (!report.invoice__created_from_challan && (report.status === 'Created' || report.status === 'Pickup Initiated')) {
            groupWiseReports[report.rack_group_no].push(report.id)
          }
        })
      })
      return groupWiseReports
    },
    selectedRackGroups() {
      return this.$store.getters['operations/selectedRackGroups']
    },
    displayRGDetailsId() {
      return this.$store.getters['operations/displayRGDetailsId']
    },
  },
  watch: {
    filters: {
      handler() {
        this.currentPage = 1
        this.fetchData()
      },
      deep: true,
    },
  },
  created() {
    WS.joinRoom(WS_ROOMS.RG_REPORT_STATUS)
    bus.$on(WS_EVENTS.RG_REPORT_CREATED, this.onRGReportCreated)
    bus.$on(WS_EVENTS.RG_REPORT_UPDATED, this.onRGReportUpdated)
  },
  destroyed() {
    WS.leaveRoom(WS_ROOMS.RG_REPORT_STATUS)
    bus.$off(WS_EVENTS.RG_REPORT_CREATED, this.onRGReportCreated)
    bus.$off(WS_EVENTS.RG_REPORT_UPDATED, this.onRGReportUpdated)
  },
  mounted() {
    this.fetchData()
    this.fetchOptions()
  },
  beforeDestroy() {
    this.clearRackGroupSelection()
  },
  methods: {
    onRGReportCreated() {
      this.isNewItemAvailable = true
    },
    onRGReportUpdated(updatedItem) {
      this.tableItems = this.tableItems.map(tableItem => {
        const updatedRackGroups = tableItem.rg_reports.map(rackGroup => {
          if (updatedItem.id === rackGroup.id) {
            return {
              ...rackGroup,
              ...updatedItem,
            }
          }
          return rackGroup
        })

        return {
          ...tableItem,
          rg_reports: updatedRackGroups,
        }
      })
    },
    handlePageChange(page) {
      this.currentPage = page
      this.fetchData()
    },
    handleRefresh() {
      this.refreshTopVisuals = true
      this.fetchData()
    },
    async fetchOptions() {
      this.loading = true
      try {
        const { data } = await axios.get('operations/rack_group_options')
        this.rackGroupOptions = data.group_options
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Failed to fetch RackGroup options!'
      } finally {
        this.loading = false
      }
    },
    async fetchData() {
      try {
        this.loading = true
        this.errorMessage = null

        const params = {
          sort_by: this.sortBy,
          sort_desc: this.sortDesc,
          page: this.currentPage,
          per_page: this.perPage,
          bill_no: this.filters.bill_no,
          status: this.filters.status,
          rack_groups: this.filters.rackGroups,
          areas: this.filters.areas,
          buyers: this.filters.buyers,
          created_by: this.filters.createdBy,
          attention_required: this.filters.attentionRequired,

        }

        const { date } = this.filters
        if (date) {
          const [fromDate, toDate] = date.split(' to ')
          if (fromDate && toDate) {
            params.date_from = fromDate
            params.date_to = toDate
          }
        }

        const { data } = await axios.get(`/operations/bill_rack_group_status?${getParsedQueryString(params)}`)
        this.totalItems = data.count
        this.tableItems = data.results
      } catch (error) {
        this.errorMessage = error?.response?.data?.error || 'Failed to fetch data!'
      } finally {
        this.loading = false
        this.isNewItemAvailable = false
        this.refreshTopVisuals = false
        this.clearRackGroupSelection()
      }
    },
    sortingChanged(ctx) {
      if (this.tableItems.length < 1) {
        return
      }
      this.sortBy = ctx.sortBy
      this.sortDesc = ctx.sortDesc
      this.fetchData()
    },
    groupSelectAllHandler(isSelected, group) {
      const groupIds = this.selectableGroupWiseRgReports[group]

      if (isSelected) {
        this.$store.dispatch('operations/selectRackGroups', { ids: groupIds })
      } else {
        this.$store.dispatch('operations/unSelectRackGroups', { ids: groupIds })
      }
    },
    downloadRGReport() {
      this.isDownloading = true
      const payload = { ids: this.selectedRackGroups }
      axios.post('/operations/download_rack_group_report', payload, {
        responseType: 'blob',
      }).then(res => {
        const { data } = res
        this.downloadXLSX(data, 'RackGroupReport')
        this.clearRackGroupSelection()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.detail || 'Failed to download!',
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.isDownloading = false
      })
    },
    clearRackGroupSelection() {
      this.$store.dispatch('operations/clearRackGroupSelection')
    },
    completeRGReport() {
      this.isCompleting = true
      const payload = { ids: this.selectedRackGroups }
      axios.post('/operations/complete_rg_report', payload).then(() => {
        this.clearRackGroupSelection()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Update failed!',
            icon: 'CheckIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.isCompleting = false
      })
    },
    viewDiffrenceHandler(id, mode) {
      this.displayBillDifferenceId = id
      this.displayBillDifferenceMode = mode
    },
    handleCloseDifferenceModal() {
      this.displayBillDifferenceId = null
    },
    closeRGDetailsModal() {
      this.$store.dispatch('operations/displayRGReportDetails', { id: null })
    },
  },

}
</script>

<style lang="scss" scoped>
.refresh-btn {
  position: relative;

  .badge {
    height: 9px;
    width: 9px;
    min-width: 1px;
    min-height: 1px;
    right: 17px;
    top: 14px;
  }

}
</style>
