<template>
  <b-modal
    v-model="showModal"
    title="Bill Details"
    size="xl"
    no-close-on-backdrop
    ok-only
    ok-title="Close"
    scrollable
    @ok="$emit('modal-closed')"
    @close="$emit('modal-closed')"
    @hidden="$emit('modal-closed')"
  >
    <b-alert
      variant="danger"
      :show="errorMessage ? true : false"
    >
      <div
        v-if="typeof errorMessage === 'object'"
        class="alert-body"
      >
        <p
          v-for="(message, index) in errorMessage"
          :key="index"
        >
          {{ message }}
        </p>
      </div>
      <p
        v-else
        class="alert-body"
      >
        {{ errorMessage }}
      </p>
    </b-alert>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        label="Small Spinner"
        variant="primary"
      />
    </div>
    <div v-if="!loading && !errorMessage">

      <div style="width:50%">
        <b-table-simple
          responsive
          hover
          class="compact-table"
        >
          <b-tbody>
            <b-tr>
              <b-th> Bill No </b-th>
              <b-td> {{ rgStatusDetails.invoice__bill_no }} </b-td>
              <b-th> Buyer </b-th>
              <b-td> {{ rgStatusDetails.invoice__party__identifier }} </b-td>
            </b-tr>
            <b-tr>
              <b-th> Attachments </b-th>

              <b-td colspan="3">
                <span
                  v-for="(item, index) in rgStatusDetails.bill_attachments"
                  :key="index"
                >
                  <a
                    :href="item.file"
                    target="_blank"
                  >
                    <span>View File {{ index+ 1 }} </span>
                    <span v-if="index != rgStatusDetails.bill_attachments.length - 1">, </span>
                  </a>
                </span>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <b-tabs>
        <b-tab
          title="Bill Details"
          :active="mode === 'items'"
        >
          <b-table
            striped
            hover
            class="compact-table"
            :fields="tableColumns"
            :items="rgStatusDetails.invoice_items"
          />
        </b-tab>
        <b-tab
          title="Comparison Details"
          :active="mode === 'difference'"
        >
          <div
            id="content-holder"
            v-html="rgStatusDetails.difference_html"
          />
        </b-tab>
      </b-tabs>

    </div>
  </b-modal>
</template>

<script>
import {
  BModal, BSpinner, BAlert, BTableSimple, BTr, BTh, BTd, BTbody, BTable, BTab, BTabs,
} from 'bootstrap-vue'

import axios from 'axios'

export default {
  components: {
    BModal,
    BSpinner,
    BAlert,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTable,
    BTab,
    BTabs,
  },
  props: {
    id: { type: Number, required: true },
    mode: { type: String, default: 'items' },
  },
  data() {
    return {
      showModal: true,
      rgStatusDetails: {},
      loading: false,
      errorMessage: '',
      tableColumns: ['product', 'pack', 'rack_no', 'rack_group_no', 'quantity', 'batch', 'expiry', 'rate', 'mrp', 'company'],
    }
  },
  mounted() {
    this.fetchDifferenceDetails()
  },
  methods: {
    async fetchDifferenceDetails() {
      try {
        this.loading = true
        const { data } = await axios.get(`/operations/bill_rack_group_status/${this.$props.id}/`)
        this.rgStatusDetails = data
      } catch (error) {
        this.errorMessage = error?.response?.data?.detail || 'Failed to fetch details!'
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
