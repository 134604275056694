<template>
  <div style="display: flex;">
    <rack-group-item
      v-for="report in rgReports"
      :key="report.id"
      :rg-report="report"
    /></div>
</template>

<script>
import RackGroupItem from './RackGroupItem.vue'

export default {
  components: {
    RackGroupItem,
  },
  props: {
    rgReports: {
      type: Array,
      default: () => [],
    },

  },
}
</script>
